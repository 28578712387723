import React from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import Link from 'next/link';
import { useForm } from 'react-hook-form';
import { InformationCircleIcon } from '@heroicons/react/solid';
import { HTTPError, readJSONFile, removeAutoComplete } from '@utils';
import { InformationBox } from '../../common';
import { FirebaseIntegrationAttributes } from '../types';
import { Modal } from '@components/common';

const FormFields = {
  integrationFormId: 'firebase_integration',
  serviceAccountId: 'service_account_id',
  projectId: 'project_id',
  databaseUrl: 'database_url',
  privateKey: 'private_key',
} as const;

interface FirebaseModalProps {
  isOpen: boolean;
  onClose: () => void;
  existingSettings: Partial<FirebaseIntegrationAttributes> | undefined;
  onSave: (values: FirebaseIntegrationAttributes) => Promise<void>;
}

export function FirebaseModal({
  isOpen,
  onClose,
  existingSettings,
  onSave,
}: FirebaseModalProps): JSX.Element {
  const [submitError, setSubmitError] = React.useState<string | null>(null);
  const [fileSchemaError, setFileSchemaError] = React.useState<string | null>(
    null,
  );
  const fileRef = React.useRef<HTMLInputElement>(null);
  const {
    handleSubmit,
    formState: { isSubmitting, errors },
    reset,
    register,
    setError,
    setValue,
  } = useForm();

  React.useEffect(() => {
    if (!existingSettings) {
      return;
    }

    reset(existingSettings);
  }, [existingSettings, reset]);

  const promptForFile = () => {
    fileRef.current?.click();
  };

  const handleFileUpload = async (ev: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setFileSchemaError(null);
      const files = ev.currentTarget.files ?? [];

      const firebaseJSONContents = (await readJSONFile(
        files[0],
      )) as FirebaseIntegrationAttributes & { client_email };

      /** Check for the existence of the important fields. We are otherwise liberal with the rest of the fields we accept. */
      if (
        !firebaseJSONContents.client_email ||
        !firebaseJSONContents.private_key ||
        !firebaseJSONContents.project_id
      ) {
        throw Error();
      }

      /** In the JSON file downloaded from Firebase, the service_account_id is client_email  */
      firebaseJSONContents.service_account_id =
        firebaseJSONContents.client_email;
      delete firebaseJSONContents.client_email;

      Object.entries(firebaseJSONContents).forEach(([key, value]) =>
        setValue(key, value),
      );
    } catch (err) {
      setFileSchemaError(
        'Something went wrong with this file, please try again',
      );
    }
  };

  const setServerErrors = (error: HTTPError) => {
    Object.values(FormFields).forEach(field => {
      error?.getFieldErrorMessageByName(field) &&
        setError(field, {
          type: 'manual',
          message: error.getFieldErrorMessageByName(field),
        });
    });
  };

  const updateSettings = async (formData): Promise<void> => {
    submitError && setSubmitError(null);

    const settings: FirebaseIntegrationAttributes = {
      enabled: true,
      service_account_id: formData[FormFields.serviceAccountId],
      project_id: formData[FormFields.projectId],
      private_key: formData[FormFields.privateKey],
    };

    if (formData[FormFields.databaseUrl]) {
      settings.database_url = formData[FormFields.databaseUrl];
    }

    try {
      await onSave(settings);

      onClose();
    } catch (error) {
      try {
        setServerErrors(error);
      } catch (_) {
        setSubmitError('Something went wrong, please try again');
      }
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      autoFocus={false}
      size='xl'
      title='Firebase'
      subtitle='Configure integration'
    >
      <Modal.Body>
        <VStack
          as='form'
          id={FormFields.integrationFormId}
          onSubmit={handleSubmit(updateSettings)}
          align='stretch'
          spacing='6'
        >
          <InformationBox icon={InformationCircleIcon}>
            <Text textStyle='md-normal' display='inline-block' color='gray.500'>
              Enter this information manually or upload a JSON file from
              Firebase{' '}
              <Link href='https://clerk.com/docs/integration/firebase'>
                <Box
                  textTransform='unset'
                  color='primary.500'
                  fontWeight='medium'
                >
                  Learn more about Firebase integration.
                </Box>
              </Link>
            </Text>
          </InformationBox>

          <Box>
            <Input
              type='file'
              accept='application/JSON'
              display='none'
              ref={fileRef}
              onChange={handleFileUpload}
              data-testid='file-input'
            />
            <Button onClick={promptForFile} alignSelf='flex-start'>
              Upload JSON
            </Button>
            {fileSchemaError && (
              <Text textStyle='sm-normal' color='danger.500' mt='2'>
                {fileSchemaError}
              </Text>
            )}
          </Box>

          <Box>
            <FormControl
              isInvalid={!!errors[FormFields.serviceAccountId]}
              {...removeAutoComplete(FormFields.serviceAccountId)}
            >
              <FormLabel fontSize='sm' mb='1'>
                Service account email
              </FormLabel>
              <Input
                type='email'
                autoFocus
                {...register(FormFields.serviceAccountId, {
                  required: 'Required',
                })}
              />
              <FormErrorMessage>
                {errors[FormFields.serviceAccountId]?.message}
              </FormErrorMessage>
            </FormControl>
          </Box>

          <Box>
            <FormControl
              isInvalid={!!errors[FormFields.projectId]}
              {...removeAutoComplete(FormFields.projectId)}
            >
              <FormLabel fontSize='sm' mb='1'>
                Firestore project ID
              </FormLabel>
              <Input
                {...register(FormFields.projectId, {
                  required: 'Required',
                })}
              />
              <FormErrorMessage>
                {errors[FormFields.projectId]?.message}
              </FormErrorMessage>
            </FormControl>
          </Box>

          <Box>
            <FormControl
              isInvalid={!!errors[FormFields.databaseUrl]}
              {...removeAutoComplete(FormFields.databaseUrl)}
            >
              <Flex align='center' justify='space-between' mb='0.5'>
                <FormLabel fontSize='sm' mb='0'>
                  Firebase database URL
                </FormLabel>

                <Text textStyle='sm-normal' color='gray.500'>
                  Optional
                </Text>
              </Flex>

              <Text textStyle='sm-normal' mb='2' color='gray.500'>
                Required for certain features to work, such as Realtime Database
              </Text>
              <Input {...register(FormFields.databaseUrl)} />
              <FormErrorMessage>
                {errors[FormFields.databaseUrl]?.message}
              </FormErrorMessage>
            </FormControl>
          </Box>

          <Box>
            <FormControl
              isInvalid={!!errors[FormFields.privateKey]}
              {...removeAutoComplete(FormFields.privateKey)}
            >
              <FormLabel fontSize='sm' mb='0.5'>
                Private key
              </FormLabel>
              <Text textStyle='sm-normal' mb='2' color='gray.500'>
                Paste the JSON private key provided by Firebase
              </Text>
              <Textarea
                rows={10}
                {...register(FormFields.privateKey, {
                  required: 'Required',
                })}
              />

              <FormErrorMessage>
                {errors[FormFields.privateKey]?.message}
              </FormErrorMessage>
            </FormControl>
          </Box>
        </VStack>
      </Modal.Body>

      <Modal.Footer mt='10'>
        <Flex direction='column' w='full'>
          <Flex justify='space-between' w='full' flexDir='row-reverse'>
            <Button
              form={FormFields.integrationFormId}
              type='submit'
              isLoading={isSubmitting}
            >
              Apply changes
            </Button>

            <Button type='button' variant='ghost' onClick={onClose}>
              Cancel
            </Button>
          </Flex>

          {submitError && (
            <Text
              textStyle='sm-normal'
              color='danger.500'
              textAlign='right'
              mt='2'
            >
              {submitError}
            </Text>
          )}
        </Flex>
      </Modal.Footer>
    </Modal>
  );
}
