import React from 'react';

import { CardDualPanel, InputBox } from '@components/common';

import { ConfirmationModal } from '@components/modal';
import {
  Button,
  useDisclosure,
  Flex,
  Text,
  InputGroup,
  InputRightAddon,
  InputLeftAddon,
  Input,
  Icon,
} from '@chakra-ui/react';

import { useDashboardCRUD, useToast } from '@hooks';

import { ExclamationIcon } from '@heroicons/react/solid';
import { useTLDParser } from '@hooks/useTLDParser';
import { noop } from '@utils';

const FORM_ID = 'change_subdomain';
const ChangeSubDomainBody = ({
  domain,
  subdomain,
  setSubdomain,
  error,
  onBlur,
  onSubmit,
}) => {
  return (
    <form id={FORM_ID} onSubmit={onSubmit}>
      <Flex direction='column' gap={4}>
        <Text textStyle='md-normal'>
          Specify the subdomain where your main application lives. This will be
          used as the base for your custom redirects
        </Text>
        <Flex direction='column' gap={1}>
          <InputBox
            errorMessageProps={{ position: 'absolute' }}
            error={error}
            label='Subdomain'
            m={0}
            p={0}
          >
            <InputGroup>
              <InputLeftAddon color='rgba(0, 0, 0, 0.65)'>
                https://
              </InputLeftAddon>

              <Input
                placeholder='example'
                textAlign='right'
                value={subdomain}
                onChange={e => setSubdomain(e.target.value)}
                onBlur={onBlur}
              />
              <InputRightAddon color='rgba(0, 0, 0, 0.65)'>
                .{domain}
              </InputRightAddon>
            </InputGroup>
          </InputBox>
        </Flex>
      </Flex>
    </form>
  );
};

const ChangeSubdomain = ({
  previousSubdomain,
  domain,
  instanceId,
  onSubdomainChange,
}: {
  previousSubdomain: string;
  domain: string;
  instanceId: string;
  onSubdomainChange: () => void;
}): JSX.Element => {
  const { isOpen, onClose, getButtonProps } = useDisclosure();
  const { showErrorToast, showSuccessToast } = useToast();
  const [subdomain, setSubdomain] = React.useState('');
  const { create } = useDashboardCRUD();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [error, setError] = React.useState(null);
  const { parse } = useTLDParser();

  const validateSubdomain = () => {
    if (!subdomain) {
      return true;
    }
    const newDomain = `https://${subdomain}.${domain}`;
    const tldResult = parse(newDomain);
    if (!tldResult.subdomain) {
      return false;
    }
    return true;
  };

  async function handleChangeSubdomain(e: React.SyntheticEvent) {
    e.preventDefault();
    try {
      const newDomain = `https://${subdomain}.${domain}`;
      if (!validateSubdomain()) {
        setError({ message: 'The subdomain is not valid' });
        return;
      }
      setIsSubmitting(true);
      await create(`/v1/instances/${instanceId}/change_domain`, {
        home_url: newDomain,
      });
      showSuccessToast(`Subdomain has been updated to ${newDomain}`);
      onSubdomainChange();
      handleClose();
    } catch (err) {
      if (err?.fieldErrors && err?.fieldErrors.length > 0) {
        setError({ message: err?.fieldErrors[0].long_message });
      } else if (err?.globalErrors && err?.globalErrors.length > 0) {
        setError({ message: err?.globalErrors[0].long_message });
      } else {
        showErrorToast('Could not update subdomain');
      }
    } finally {
      setIsSubmitting(false);
    }
  }

  const handleClose = () => {
    setError(null);
    setSubdomain('');
    onClose();
  };

  const onBlurChangeSubdomain = () => {
    if (!validateSubdomain()) {
      setError({ message: 'The subdomain is not valid' });
      return;
    }
    setError(null);
  };

  return (
    <CardDualPanel
      alignItems='center'
      rightPanelStyles={{ justifyContent: 'space-between' }}
      title={previousSubdomain ? 'Change subdomain' : 'Set subdomain'}
      subtitle={
        <Text as='span' textStyle='sm-normal' color='gray.500'>
          Currently deployed on https://
          <Text as='span' textStyle='sm-medium' color='gray.500'>
            {previousSubdomain ? `${previousSubdomain}.` : ''}
          </Text>
          <Text as='span'>{domain}</Text>
        </Text>
      }
    >
      <Button
        leftIcon={<Icon as={ExclamationIcon} />}
        fontWeight='bold'
        fontSize='xs'
        ml='auto'
        {...getButtonProps()}
        colorScheme='red'
      >
        {previousSubdomain ? 'Change subdomain' : 'Set subdomain'}
      </Button>
      <ConfirmationModal
        autoFocusOnCancel={false}
        disableConfirmation={subdomain === ''}
        isConfirmationLoading={isSubmitting}
        alertDialogProps={{ width: '30rem' }}
        isOpen={isOpen}
        handleCancelation={handleClose}
        handleConfirmation={noop}
        confirmationMessage={
          previousSubdomain ? 'Change subdomain' : 'Set subdomain'
        }
        cancelationMessage='Cancel'
        confirmButtonIcon={ExclamationIcon}
        confirmationButtonProps={{ type: 'submit', form: FORM_ID }}
        message={
          <ChangeSubDomainBody
            onBlur={onBlurChangeSubdomain}
            error={error}
            domain={domain}
            subdomain={subdomain}
            setSubdomain={setSubdomain}
            onSubmit={handleChangeSubdomain}
          />
        }
        headerTitle={previousSubdomain ? 'Change subdomain' : 'Set subdomain'}
      />
    </CardDualPanel>
  );
};

export default React.memo(ChangeSubdomain);
