import React from 'react';
import { Box, Text, HStack } from '@chakra-ui/react';

import { SamlConnection } from '@types';
import { Image } from '@chakra-ui/image';
import { getSamlProviderLogoURL, getSamlProviderName } from '../utils';

type NameCellProps = {
  samlConnection: Pick<SamlConnection, 'name' | 'domain' | 'provider'>;
};
const NameCell = ({ samlConnection }: NameCellProps): JSX.Element => {
  return (
    <HStack align='flex-start'>
      <Image
        src={getSamlProviderLogoURL(samlConnection.provider)}
        boxSize='24px'
      />
      <Box maxW='20rem' flex='1'>
        <Text textStyle='sm-medium' noOfLines={1}>
          {samlConnection.name}
        </Text>
        <Text noOfLines={1} textStyle='sm-normal' color='gray.500'>
          {samlConnection.domain}
        </Text>
        <Text noOfLines={1} textStyle='sm-normal' color='gray.500'>
          {getSamlProviderName(samlConnection.provider)}
        </Text>
      </Box>
    </HStack>
  );
};

export default NameCell;
