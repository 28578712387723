import { PageLoader, StickyBottomCTA } from '@components/common';
import React, { useEffect } from 'react';
import { preventSubmitOnEnter } from '@utils';
import { Stack, VStack } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  AvatarSettings,
  useAvatarConfig,
  useExitProtection,
  useInstance,
  useLocation,
} from '@hooks';
import { UserAvatarPanel } from '@components/customization/avatars/UserAvatarPanel';
import { OrganizationAvatarPanel } from '@components/customization/avatars/OrganizationAvatarPanel';
import {
  AvatarCacheTimeNotice,
  AvatarImageUrlPropertyNotice,
} from './AvatarNotice';

const FORM_ID = 'avatar_settings';

const AVATAR_SETTINGS_DEFAULTS: AvatarSettings = {
  user: {
    bgType: 'marble',
    bgColor: ['6C47FF'],
    fgType: 'initials',
    fgColor: 'FFFFFF',
  },
  organization: {
    bgType: 'marble',
    bgColor: ['6C47FF'],
    fgType: 'silhouette',
    fgColor: 'FFFFFF',
  },
};

export const Avatars = (): JSX.Element => {
  const { instanceId, applicationId } = useLocation();
  const { instance } = useInstance();
  const { avatarConfig, upsertAvatarConfig } = useAvatarConfig(instanceId);
  const formMethods = useForm({
    defaultValues: AVATAR_SETTINGS_DEFAULTS,
    mode: 'onChange',
  });
  const { handleSubmit, formState, watch, reset } = formMethods;
  const { isDirty, isSubmitting } = formState;
  const values = watch();
  useExitProtection(isDirty, () => {
    return window.confirm(
      'Your Avatar settings have changed. Any unsaved changes will be lost.',
    );
  });

  useEffect(() => {
    if (!!avatarConfig && Object.keys(avatarConfig).length > 0) {
      reset(avatarConfig);
    }
  }, [avatarConfig, reset]);

  const updateConfig = () => {
    const postBody = {
      ...values,
    };
    if (postBody.user.bgType === 'solid') {
      postBody.user.bgColor = postBody.user.bgColor.slice(0, 1);
    }
    if (postBody.organization.bgType === 'solid') {
      postBody.organization.bgColor = postBody.organization.bgColor.slice(0, 1);
    }
    return upsertAvatarConfig({ arg: postBody });
  };

  if (!instanceId || !avatarConfig || !instance) {
    return <PageLoader />;
  }

  return (
    <FormProvider {...formMethods}>
      <VStack spacing={7} align='stretch' mb={7}>
        <AvatarCacheTimeNotice />
        <AvatarImageUrlPropertyNotice />
        <form
          id={FORM_ID}
          onSubmit={handleSubmit(updateConfig)}
          onKeyDown={preventSubmitOnEnter}
        >
          <Stack spacing={8}>
            <UserAvatarPanel />
            <OrganizationAvatarPanel
              orgEnabled={instance?.organization_settings?.enabled}
              orgSettingsUrl={`/apps/${applicationId}/instances/${instanceId}/organizations-settings`}
            />
          </Stack>
        </form>
        <StickyBottomCTA
          formId={FORM_ID}
          isVisible={isDirty}
          onReset={() => reset()}
          isSubmitting={isSubmitting}
        />
      </VStack>
    </FormProvider>
  );
};
