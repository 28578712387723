import React from 'react';

import { CardDualPanel } from '@components/common';
import { ConfirmationModal } from '@components/modal';
import { Button, Text, useDisclosure } from '@chakra-ui/react';
import { useToast } from '@hooks';
import { useRouter } from 'next/router';
import { useDeleteSamlConnection } from '@components/samlConnections/api';
import { SamlConnection } from '@types';

type DeleteSamlConnectionProps = {
  samlConnection: SamlConnection;
  onDelete: () => void;
  navigateTo: string;
};

const DeleteSamlConnection = ({
  samlConnection,
  navigateTo,
  onDelete,
}: DeleteSamlConnectionProps): JSX.Element => {
  const { isOpen, onClose, getButtonProps } = useDisclosure();
  const { deleteSamlConnection } = useDeleteSamlConnection();
  const router = useRouter();
  const { showErrorToast } = useToast();

  async function handleDelete() {
    try {
      await deleteSamlConnection(samlConnection.id);
      onDelete();
      onClose();
      void router.push(navigateTo);
    } catch (err) {
      showErrorToast(
        'Error while trying to remove connection',
        'Connection has not been removed',
      );
    }
  }

  return (
    <CardDualPanel
      alignItems='center'
      rightPanelStyles={{ justifyContent: 'space-between' }}
      title='Remove connection'
      subtitle='Delete this connection. Users who created their account with SAML SSO will need to re-verify their email addresses and reset passwords, if set.'
    >
      <Button
        fontWeight='bold'
        fontSize='xs'
        ml='auto'
        {...getButtonProps()}
        colorScheme='red'
      >
        Remove Connection
      </Button>

      <ConfirmationModal
        verification={{
          stringToMatch: samlConnection.name,
          label: 'Connection name',
          text: 'Type the name of the connection below to confirm.',
        }}
        isOpen={isOpen}
        handleCancelation={onClose}
        handleConfirmation={handleDelete}
        confirmationMessage='Remove connection'
        cancelationMessage='Cancel'
        message={
          <>
            <Text textStyle='md-normal'>
              Are you sure you want to remove{' '}
              <Text as='span' textStyle='md-semibold'>
                {samlConnection.name}
              </Text>
              ?
            </Text>
            <Text textStyle='md-normal'>
              Users will no longer be able to use this enterprise connection to
              sign in. Any features that rely on this connection may cease to
              function.
            </Text>
          </>
        }
        headerTitle='Remove connection'
      />
    </CardDualPanel>
  );
};

export default React.memo(DeleteSamlConnection);
