import React from 'react';

import { useRouter } from 'next/router';

import { samlConnectionSchema, SamlConnectionSchema } from './schema';

import useSamlConnectionsPathAndKeys from '../hooks/useSamlConnectionsPathAndKeys';
import {
  SamlConnection,
  TableSortingDirections,
  TableSortingData,
} from '@types';
import { PAGE_SIZE } from '../constants';
import { useGetSamlConnections } from '../api';
import { useLocation } from '@hooks';

const makeSamlConnectionSchema = samlConnectionSchema();

type SamlConnectionTableReturnType = {
  data: SamlConnection[];
  schema: SamlConnectionSchema;
  page: number;
  query: string;
  path: string;
  isLoading: boolean;
  sortingState: TableSortingData;
  hasNextPage: boolean;
  onRowClicked: (samlConnection: SamlConnection) => void;
  onPaginate: (value: number) => void;
  onSorted: (data: TableSortingData) => void;
};

const useTable = (): SamlConnectionTableReturnType => {
  const [sortingState, setSortingState] = React.useState<TableSortingData>({
    column: 'name',
    direction: TableSortingDirections.DESC, //TODO MAKE GET DEFAULT FROM QUERY PARAMS IF EXISTS
  });
  const { instanceId, applicationId } = useLocation();
  const router = useRouter();
  const { path } = useSamlConnectionsPathAndKeys();

  const { samlConnections, hasLoaded, totalCount } = useGetSamlConnections({});

  const samlConnectionsPath = `/apps/${applicationId}/instances/${instanceId}/user-authentication/enterprise-connections`;
  const page = parseInt(router.query.page as string, 10) || 1;

  const onRowClicked = React.useCallback(
    (samlConnection: SamlConnection) => {
      void router.push({
        pathname: `${samlConnectionsPath}/${samlConnection.id}`,
      });
    },
    [page, samlConnectionsPath, router],
  );

  const onPaginate = (value: number) => {
    void router.push({ query: { ...router.query, page: value } });
  };

  const onSorted = (data: TableSortingData) => {
    setSortingState(data);
    void router.push({
      query: {
        ...router.query,
        order_by: `${sortingState.direction}${sortingState.column}`,
      },
    });
    if (page > 1) {
      onPaginate(1);
    }
  };

  const hasNextPage = React.useMemo(() => {
    if (!totalCount) {
      return false;
    }

    return totalCount > PAGE_SIZE * page;
  }, [page, totalCount]);

  return {
    data: samlConnections,
    isLoading: !hasLoaded,
    schema: makeSamlConnectionSchema,
    page,
    query: (router.query.query as string) || '',
    onRowClicked,
    path,
    hasNextPage,
    sortingState,
    onPaginate,
    onSorted,
  };
};

export default useTable;
