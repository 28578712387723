import React from 'react';
import cn from 'classnames';
import { Card as SharedCard, Heading } from '@clerk-ui/components';
import styles from './Card.module.scss';

export type CardProps = {
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  notice?: React.ReactNode;
  className?: string;
  subtitleClassName?: string;
  noticeClassName?: string;
  headingTag?: 'h2' | 'h3' | 'h4';
  id?: string;
  infobox?: React.ReactNode;
  children: React.ReactNode;
};

export function Card({
  title,
  subtitle,
  notice,
  children,
  className,
  subtitleClassName,
  noticeClassName,
  headingTag = 'h3',
  id,
  infobox,
}: CardProps): JSX.Element {
  return (
    <SharedCard className={cn(styles.card, className)}>
      {infobox}
      {title && (
        <Heading id={id} as={headingTag}>
          {title}
        </Heading>
      )}
      {subtitle && (
        <p className={cn(styles.subtitle, subtitleClassName)}>{subtitle}</p>
      )}
      {notice && <p className={cn(styles.notice, noticeClassName)}>{notice}</p>}
      {children}
    </SharedCard>
  );
}
