import React from 'react';
import { Box, Tooltip } from '@chakra-ui/react';
import { useInstance } from '@hooks';
import { isProduction } from '@utils';

export function EnableInProdOnly({
  tooltipLabel = 'Production instance only',
  children,
}: {
  tooltipLabel?: string;
  children: JSX.Element;
}): JSX.Element {
  // TODO: A better abstraction for this
  // maybe a Context that includes the current app and instance etc
  const { instance } = useInstance();
  const isProd = instance && isProduction(instance.environment_type);

  if (isProd) {
    return children;
  }

  return React.Children.map(children, (c: any) => {
    return (
      <Tooltip label={tooltipLabel}>
        <Box>
          {React.cloneElement(c, {
            ...c.props,
            pointerEvents: 'none',
            href: null,
          })}
        </Box>
      </Tooltip>
    );
  });
}
