import React from 'react';
import { Flex, Icon, Box, Text } from '@chakra-ui/react';
import NextLink from 'next/link';

interface SlugLink {
  title: string;
  href: string;
}

type SlugElement = string | SlugLink;

export type BreadCrumbProps =
  | {
      icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
      slug: SlugElement | SlugElement[];
      fallbackTitle?: string | JSX.Element;
    }
  | {
      icon?: never;
      slug?: never;
      fallbackTitle?: never;
    };

function Slash(): JSX.Element {
  return (
    <Text textStyle='sm-normal' color='gray.200' mx={3}>
      /
    </Text>
  );
}

//TODO rething Array.isArray(slug)

const BreadCrumbs = ({
  icon,
  slug,
  fallbackTitle,
}: BreadCrumbProps): JSX.Element => {
  const isSlugAnArray = Array.isArray(slug);
  return (
    <Flex align='center' mb={6}>
      <Icon
        as={icon}
        color={isSlugAnArray ? 'gray.300' : 'black'}
        mr={1}
        pos='relative'
        bottom='1px'
      />

      {isSlugAnArray ? (
        slug.map((slugElement, index) => {
          let slugTitle, slugLink;

          if (typeof slugElement === 'object') {
            slugTitle = slugElement.title;
            slugLink = slugElement.href;
          } else {
            slugTitle = slugElement;
          }

          const isLast = index === slug.length - 1;
          const color = isLast ? 'black' : 'gray.500';

          return (
            <Flex key={slugTitle} align='center'>
              {slugLink && (
                <NextLink href={slugLink}>
                  <Box
                    as='span'
                    textStyle='sm-normal'
                    color={color}
                    _hover={{ color: 'unset' }}
                  >
                    {slugTitle}
                  </Box>
                </NextLink>
              )}

              {!slugLink && (
                <Text
                  maxWidth='xs'
                  textOverflow='ellipsis'
                  whiteSpace='nowrap'
                  textStyle='sm-normal'
                  color={color}
                  overflow='hidden'
                >
                  {slugTitle}
                </Text>
              )}

              {!isLast && <Slash />}
            </Flex>
          );
        })
      ) : (
        <>
          <Text textStyle='sm-normal' color={'black'}>
            {fallbackTitle}
          </Text>
        </>
      )}
    </Flex>
  );
};

export default BreadCrumbs;
