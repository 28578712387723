import React from 'react';
import { useDashboardSWRMutation } from '@hooks';

import { useTLDParser } from '@hooks/useTLDParser';

type UseChooseDomainValidationReturn = {
  validate: (onValidate: (isValidating: boolean) => void) => Promise<{
    isValid: boolean;
    error: string | null;
  }>;
};

const useChooseDomainValidation = (
  newDomain: string,
): UseChooseDomainValidationReturn => {
  const { parse } = useTLDParser();

  const { trigger } = useDashboardSWRMutation(
    `/v1/domains/${newDomain}/exists`,
  );

  const checkIfDomainExists = React.useCallback(
    async (onValidate: (isValidating: boolean) => void) => {
      try {
        onValidate(true);
        await trigger();
        return { isValid: true, error: null };
      } catch (err) {
        return {
          isValid: false,
          error: 'Domain is not available',
        };
      } finally {
        onValidate(false);
      }
    },
    [trigger],
  );

  const validate: UseChooseDomainValidationReturn['validate'] =
    React.useCallback(
      async onValidate => {
        if (!newDomain) {
          return { isValid: true, error: null };
        }

        const domainWithScheme = `https://${newDomain}`;

        const tldResult = parse(domainWithScheme);

        if (tldResult.subdomain) {
          return {
            isValid: false,
            error:
              'You can only enter root domains, subdomains can be set via the paths page.',
          };
        }

        if (!tldResult.domain) {
          return {
            isValid: false,
            error: 'The root domain is not valid',
          };
        }

        const makeRemoteValidation = await checkIfDomainExists(onValidate);

        if (!makeRemoteValidation.isValid) {
          return { isValid: false, error: makeRemoteValidation.error };
        }

        return { isValid: true, error: null };
      },

      [newDomain, checkIfDomainExists, parse],
    );

  return { validate };
};

export default useChooseDomainValidation;
