import React from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputRightAddon,
  Switch,
  Text,
  useClipboard,
  VStack,
} from '@chakra-ui/react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { getModalFields } from './constants';
import type { OAuthProviderSettings } from '@types';
import { isProduction } from '@utils';
import { HelperLink, Modal } from '@components/common';
import { ProviderTextInput } from './ProviderTextInput';
import { ClientSecretInput } from './ClientSecretInput';
import { AdditionalScopeInput } from './AdditionalScopeInput';
import { ExtraOauthSettingInputs } from './ExtraOauthSettingInputs';
import { AuthenticatableSwitchField } from './AuthenticatableSwitchField';

interface ConfigureProviderModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (data: Partial<OAuthProviderSettings>) => void;
  oauthProviderSettings: OAuthProviderSettings;
  oauthCallbackUrl: string;
  environmentType: string;
}

export function ConfigureProviderModal({
  isOpen,
  onClose,
  onSave,
  oauthProviderSettings,
  oauthCallbackUrl,
  environmentType,
}: ConfigureProviderModalProps): JSX.Element {
  const { provider, name } = oauthProviderSettings;
  const redirectClipboard = useClipboard(oauthCallbackUrl);
  const modalFields = getModalFields(provider);
  const isProdInstance = isProduction(environmentType);
  const formMethods = useForm({ mode: 'onSubmit' });
  const formId = React.useMemo(() => `${provider}-oauth-form`, [provider]);
  const initialRef = React.useRef();
  const mustUseCustomCredentials =
    isProdInstance || !oauthProviderSettings.dev_credentials_available;

  const {
    control,
    handleSubmit,
    register,
    formState: { isSubmitting },
    reset,
  } = formMethods;

  // enforce a custom profile for production instances and dev OAuth providers for which we can't offer dev credentials
  const defaultCustomProfile =
    mustUseCustomCredentials || oauthProviderSettings.custom_profile;
  const customProfileField = register('custom_profile');

  const customProfile = useWatch({
    control,
    name: 'custom_profile',
    defaultValue: defaultCustomProfile,
  });

  const onSubmit = async ({
    authenticatable,
    custom_profile,
    client_id,
    client_secret,
    additional_scopes,
    ...rest // capture all extra_settings
  }) => {
    const data = {
      strategy: oauthProviderSettings.strategy,
      enabled: true,
      custom_profile,
      ...(custom_profile ? { client_id } : {}),
      ...(custom_profile ? { client_secret } : {}),
      ...(custom_profile ? { additional_scopes } : {}),
      extra_settings: { ...rest },
      required: false,
      authenticatable,
    };

    // @ts-ignore
    // TS complains for extra settings schema
    await onSave(data);

    handleClose();
  };

  const handleClose = () => {
    onClose();
    reset();
  };

  if (!modalFields) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title={name}
      subtitle='Configure connection'
      initialFocusRef={initialRef}
    >
      <Modal.Body>
        <FormProvider {...formMethods}>
          <VStack
            as='form'
            id={formId}
            onSubmit={handleSubmit(onSubmit)}
            align='stretch'
            spacing='6'
          >
            <AuthenticatableSwitchField
              oauthProviderSettings={oauthProviderSettings}
              isDisabled={isSubmitting}
            />

            <Box>
              <FormControl>
                <HStack>
                  <Box>
                    <FormLabel fontSize='sm' mb='0.5' htmlFor='custom_profile'>
                      Use custom credentials
                    </FormLabel>

                    <Text color='gray.500' textStyle='md-normal' w='full'>
                      Enable this to provide your own credentials, If turned
                      off, the OAuth consent screen will be displayed every time
                      a user attempts to sign in.
                    </Text>

                    {mustUseCustomCredentials && (
                      <Text textStyle='sm-normal' mb='2' mt='2'>
                        <strong>Note:</strong>{' '}
                        {isProdInstance
                          ? 'You must provide your own credentials on production instances.'
                          : 'This provider does not support shared development credentials'}
                      </Text>
                    )}

                    <HelperLink href={oauthProviderSettings.docsUrl}>
                      Learn more about custom credentials & further settings for{' '}
                      {name}
                    </HelperLink>
                  </Box>

                  <Box alignSelf='baseline'>
                    <Switch
                      id='custom_profile'
                      defaultChecked={defaultCustomProfile}
                      isChecked={customProfile}
                      isDisabled={isSubmitting}
                      isReadOnly={mustUseCustomCredentials}
                      {...customProfileField}
                    />
                  </Box>
                </HStack>
              </FormControl>
            </Box>

            <ProviderTextInput
              oauthProviderSettings={oauthProviderSettings}
              field='client_id'
              isExtraSetting={false}
              customProfile={customProfile}
            />

            <ClientSecretInput
              oauthProviderSettings={oauthProviderSettings}
              customProfile={customProfile}
            />

            <ExtraOauthSettingInputs
              oauthProviderSettings={oauthProviderSettings}
              customProfile={customProfile}
            />

            {customProfile && (
              <Box>
                <Text textStyle='md-medium' mb='0.5'>
                  {modalFields.redirect.label}
                </Text>

                <Text color='gray.500' textStyle='md-normal' w='full'>
                  {modalFields.redirect.description}
                </Text>

                <InputGroup
                  variant='addonAction'
                  borderWidth='1px'
                  borderRadius='md'
                  backgroundColor='gray.50'
                >
                  <Input
                    readOnly
                    placeholder={oauthCallbackUrl}
                    tabIndex={-1}
                    isDisabled={true}
                  />
                  <InputRightAddon>
                    <Button variant='link' onClick={redirectClipboard.onCopy}>
                      {redirectClipboard.hasCopied ? 'Copied' : 'Copy'}
                    </Button>
                  </InputRightAddon>
                </InputGroup>
              </Box>
            )}

            <Box>
              <AdditionalScopeInput
                label={modalFields.additional_scopes.label}
                description={modalFields.additional_scopes.description}
                oauthProviderSettings={oauthProviderSettings}
                customProfile={customProfile}
              />
            </Box>
          </VStack>
        </FormProvider>
      </Modal.Body>

      <Modal.Footer flexDir='row-reverse'>
        <Button type='submit' form={formId} isLoading={isSubmitting}>
          Save
        </Button>

        <Button variant='ghost' onClick={handleClose} ref={initialRef}>
          Back
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
