import React from 'react';
import { FormControl, Input, VStack } from '@chakra-ui/react';
import { CardDualPanel, InputBox } from '@components/common';
import { useFormContext } from 'react-hook-form';
import { CertificateUploaderBox } from './CertificateUploaderBox';

export function SamlConnectionForm(): JSX.Element {
  const {
    register,
    formState: { errors, isSubmitting },
  } = useFormContext();

  return (
    <VStack spacing='7' align='stretch' mb='7'>
      <CardDualPanel
        title='Connection details'
        subtitle='Specify the name and domain for this connection.'
      >
        <InputBox label='Name' error={errors.name}>
          <FormControl isInvalid={!!errors.name}>
            <Input
              placeholder='My connection'
              isDisabled={isSubmitting}
              {...register('name', {
                required: 'Name is required',
              })}
            />
          </FormControl>
        </InputBox>

        <InputBox label='Domain' error={errors.domain}>
          <FormControl isInvalid={!!errors.domain}>
            <Input
              placeholder='example.com'
              isDisabled={isSubmitting}
              {...register('domain', {
                required: 'Domain is required',
              })}
            />
          </FormControl>
        </InputBox>
      </CardDualPanel>

      <CardDualPanel
        title='Identity provider configuration'
        subtitle='Retrieve these values from your identity provider and input them here.'
      >
        <InputBox label='IdP SSO URL' error={errors.idp_sso_url}>
          <FormControl isInvalid={!!errors.idp_sso_url}>
            <Input
              isDisabled={isSubmitting}
              placeholder='https://login.microsoftonline.com/00000000-0000-0000-0000-000000000000/saml2'
              {...register('idp_sso_url', {
                required: 'IdP SSO URL is required and must be a valid URL',
              })}
            />
          </FormControl>
        </InputBox>

        <InputBox label='IdP Entity ID' error={errors.idp_entity_id}>
          <FormControl isInvalid={!!errors.idp_entity_id}>
            <Input
              placeholder='https://sts.windows.net/00000000-0000-0000-0000-000000000000/'
              isDisabled={isSubmitting}
              {...register('idp_entity_id', {
                required: 'IdP Entity ID is required',
              })}
            />
          </FormControl>
        </InputBox>

        <InputBox label='Certificate' error={errors.idp_certificate}>
          <FormControl isInvalid={!!errors.idp_certificate}>
            <CertificateUploaderBox />
          </FormControl>
        </InputBox>
      </CardDualPanel>
    </VStack>
  );
}
