import React from 'react';
import NextLink from 'next/link';
import { CardContainer } from '@components/common';
import { Text, Flex, Box } from '@chakra-ui/react';
import { useLocation } from '@hooks';

export function OrganizationsEnablePrompt(): JSX.Element {
  const { applicationId, instanceId } = useLocation();

  return (
    <CardContainer spacing={6}>
      <Flex justifyContent='center'>
        <Text
          textStyle='sm-normal'
          display='inline-block'
          color='gray.500'
          mt='0.5'
        >
          Organizations have not been enabled for this application.{' '}
          <NextLink
            href={`/apps/${applicationId}/instances/${instanceId}/organizations-settings`}
          >
            <Box
              as='span'
              textStyle='sm-normal'
              color='primary.500'
              fontWeight={500}
            >
              Enable organizations
            </Box>
          </NextLink>{' '}
        </Text>
      </Flex>
    </CardContainer>
  );
}
