import { useLocation } from '@hooks';
import { useSession } from '@clerk/nextjs';
import { buildCacheKey } from '@utils/api';

import { PAGE_SIZE } from '../constants';

const useSamlConnectionsPathAndKeys = ({
  orderBy,
}: {
  orderBy?: string;
} = {}): {
  cacheKey: string;
  path: string;
  pathWithParams: string;
} => {
  const { instanceId, query } = useLocation();
  const { session } = useSession();

  const search = (query?.query as string) || '';
  const page = parseInt(query.page as string, 10) || 1;
  const order_by = orderBy || (query?.order_by as string);

  const params = new URLSearchParams({
    query: search,
    offset: PAGE_SIZE * (page - 1) + '',
    ...(order_by ? { order_by } : {}),
  });

  const samlConnectionRootPath = `/v1/instances/${instanceId}/saml_connections`;
  const pathWithParams = `${samlConnectionRootPath}?${params.toString()}`;

  const cacheKey = buildCacheKey({
    path: pathWithParams,
    session,
  });

  return { cacheKey, pathWithParams, path: samlConnectionRootPath };
};

export default useSamlConnectionsPathAndKeys;
