import React from 'react';
import { Form, Modal, TitledCard } from '@clerk-ui/components';

import styles from './JsonEditorModal.module.scss';
import Editor from '@monaco-editor/react';
import { editor } from 'monaco-editor';
import { exampleJsonData, isExampleData, normalizeJson } from './utils';

interface EditorError {
  message: string;
  line?: number;
}

interface JsonEditorModalState {
  error: EditorError | null;
  ready: boolean;
  value: string;
  saveRequested: boolean;
}

type OnSuccessCallback = (afterVerifyCallback?: () => any) => void;
type OnErrorCallback = (errorMessage?: string) => void;

export type SubmitJsonHandler = (
  jsonValue: string,
  onSuccess: OnSuccessCallback,
  onError: OnErrorCallback,
) => void;

export interface JsonEditorModalProps {
  jsonValue: string | null;
  title: string;
  subtitle: string;
  description?: JSX.Element | string;
  descriptionIcon?: JSX.Element | null;
  maxSize?: number;
  handleClose: () => void;
  submitJsonHandler: SubmitJsonHandler;
}

const editorOptions: editor.IStandaloneEditorConstructionOptions = {
  formatOnType: true,
  formatOnPaste: true,
  autoIndent: 'full',
  codeLens: false,
  minimap: { enabled: false },
  padding: { top: 16, bottom: 16 },
  // hide *all* suggestions
  quickSuggestions: false,
  acceptSuggestionOnEnter: 'off',
  acceptSuggestionOnCommitCharacter: false,
  tabCompletion: 'off',
};

export function JsonEditorModal({
  jsonValue,
  subtitle,
  title,
  description,
  descriptionIcon,
  handleClose,
  submitJsonHandler,
  maxSize = 4096,
}: JsonEditorModalProps): JSX.Element {
  const editorRef = React.useRef<editor.ICodeEditor | null>(null);
  const [state, setState] = React.useState<JsonEditorModalState>({
    error: null,
    ready: false,
    value: jsonValue || exampleJsonData,
    saveRequested: false,
  });
  const { error, ready, saveRequested, value } = state;

  React.useEffect(() => {
    return () => editorRef.current?.dispose();
  }, []);

  React.useEffect(() => {
    if (!ready || !saveRequested) {
      return;
    }
    save();
  }, [ready, saveRequested]);

  const save = () => {
    let jsonToSubmit: string;
    if (!value.trim()) {
      jsonToSubmit = '{}';
    } else {
      try {
        jsonToSubmit = normalizeJson(value);
      } catch (_) {
        jsonToSubmit = jsonValue;
      }
    }

    const onSuccess: OnSuccessCallback = afterVerifyCallback => {
      afterVerifyCallback?.();
    };
    const onFailure: OnErrorCallback = errorMessage => {
      setState({
        ...state,
        error: { message: errorMessage },
      });
    };
    submitJsonHandler(jsonToSubmit, onSuccess, onFailure);
  };

  const handleOnMount = (editor: editor.IStandaloneCodeEditor) => {
    // manually format before monaco formatters are attached
    editorRef.current = editor;
    editor
      .getModel()
      .setValue(
        JSON.stringify(JSON.parse(editor.getModel().getValue()), null, '\t'),
      );
    editor.focus();
  };

  const handleOnValidate = (markers: editor.IMarker[]) => {
    if (value.length > maxSize) {
      setState({
        ...state,
        error: { message: `Max allowed size is ${maxSize} bytes` },
      });
      return;
    }
    const errs = markers.filter(m => m.source === 'json');
    if (errs.length === 0) {
      setState({ ...state, error: null, ready: true });
      return;
    }
    setState({
      ...state,
      error: { message: errs[0].message, line: errs[0].startLineNumber },
    });
  };

  const handleOnChange = (value: string | undefined) => {
    setState({ ...state, ready: false, value, saveRequested: false });
  };

  const requestSave = () => {
    setState({ ...state, saveRequested: true });
  };

  return (
    <Modal active handleClose={handleClose} className={styles.container}>
      <TitledCard title={title} subtitle={subtitle}>
        {description && (
          <div className={styles.scopeInfo}>
            <div className={styles.descriptionIcon}>{descriptionIcon}</div>
            <div className={styles.description}>{description}</div>
          </div>
        )}
        <Form
          className={styles.form}
          handleReset={handleClose}
          handleSubmit={requestSave}
          submitButtonLabel='Save'
          resetButtonLabel='Cancel'
          submitButtonDisabled={!!error || isExampleData(value)}
          buttonGroupClassName={styles.buttonGroup}
        >
          <div className={styles.editor}>
            <Editor
              theme='vs-dark'
              language='json'
              value={value}
              options={editorOptions}
              onChange={handleOnChange}
              onValidate={handleOnValidate}
              onMount={handleOnMount}
              className={styles.editorHideSuggestions}
            />
          </div>
          <p className={styles.error}>
            {error &&
              `Error: ${error.message} ${
                error.line ? `(line ${error.line})` : ''
              }`}
          </p>
        </Form>
      </TitledCard>
    </Modal>
  );
}
