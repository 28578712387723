import React from 'react';

import { CardDualPanel } from '@components/common';

import { Button, Icon, Text, IconButton, Flex } from '@chakra-ui/react';
import useChangeDomain from './useChangeDomain';
import { ConfirmationModal } from '@components/modal';
import { noop } from '@utils';
import { ExclamationIcon, ArrowLeftIcon } from '@heroicons/react/solid';

const ChangeDomain = ({
  instanceId,
  subdomain,
  domain,
  onDomainChange,
}: {
  domain: string;
  subdomain: string;
  instanceId: string;
  onDomainChange: () => void;
}): JSX.Element => {
  const {
    isSubmitting,
    message,
    handleClose,
    getButtonProps,
    isOpen,
    formId,
    isConfirmDisabled,
    verification,
    goBack,
    isGoBackVisible,
    title,
    inputLeftAddon,
  } = useChangeDomain({
    previousSubdomain: subdomain,
    instanceId,
    onDomainChangeConfirmed: onDomainChange,
  });

  return (
    <CardDualPanel
      alignItems='center'
      rightPanelStyles={{ justifyContent: 'space-between' }}
      title='Change root domain'
      subtitle={
        <Text textStyle='sm-normal' color='gray.500'>
          Currently deployed on https://{subdomain ? `${subdomain}.` : ''}
          <Text as='span' textStyle='sm-medium' color='gray.500'>
            {domain}
          </Text>
        </Text>
      }
    >
      <Button
        leftIcon={<Icon as={ExclamationIcon} />}
        fontWeight='bold'
        fontSize='xs'
        ml='auto'
        {...getButtonProps()}
        colorScheme='red'
      >
        Change root domain
      </Button>

      {isOpen && (
        <ConfirmationModal
          inputLeftAddon={inputLeftAddon}
          disableConfirmation={isConfirmDisabled}
          isConfirmationLoading={isSubmitting}
          alertDialogProps={{ width: '30rem' }}
          isOpen={isOpen}
          handleCancelation={handleClose}
          handleConfirmation={noop}
          confirmationMessage='Change root domain'
          cancelationMessage='Cancel'
          confirmButtonIcon={ExclamationIcon}
          message={message}
          headerTitle={
            <Flex gap={1} alignItems='center'>
              {isGoBackVisible && (
                <IconButton
                  onClick={goBack}
                  color='black'
                  variant='ghost'
                  aria-label='go to first step'
                  icon={<Icon as={ArrowLeftIcon} boxSize={5} />}
                ></IconButton>
              )}
              {title}
            </Flex>
          }
          verification={verification}
          confirmationButtonProps={{ form: formId, type: 'submit' }}
          clearOnConfirmation={false}
          autoFocusOnCancel={false}
        />
      )}
    </CardDualPanel>
  );
};

export default React.memo(ChangeDomain);
