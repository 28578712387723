import React from 'react';
import RowActions from './RowActions';
import useTable from './useTable';
import { SamlConnection } from '@types';
import { Center, Spinner } from '@chakra-ui/react';
import { Table, DumbPagination, EmptyTable } from '@components/common';

export const SamlConnectionTable = (): JSX.Element => {
  const {
    data,
    schema,
    onRowClicked,
    isLoading,
    sortingState,
    page,
    hasNextPage,
    onPaginate,
    onSorted,
  } = useTable();

  return (
    <>
      {isLoading ? (
        <Center w='full' h='full'>
          <Spinner />
        </Center>
      ) : (
        <>
          <Table
            emptyTableFallback={
              <EmptyTable
                message='No enterprise connections to display'
                colSpan={5}
              />
            }
            tableSchema={schema}
            data={data}
            rowActions={(samlConnection: SamlConnection) => (
              <RowActions samlConnection={samlConnection} />
            )}
            onRowClicked={onRowClicked}
            onSorted={onSorted}
            sortingState={sortingState}
          />

          <DumbPagination
            currentPage={page}
            itemsThisPage={data.length}
            hasNextPage={hasNextPage}
            onPaginate={onPaginate}
          />
        </>
      )}
    </>
  );
};
