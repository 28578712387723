import React from 'react';
import cn from 'classnames';
import { ColorResult, SketchPicker } from 'react-color';
import { DropletIcon } from '../../assets/icons';
import { InputWithIcon } from '../input';
import { useDetectClickOutside } from '@hooks/useDetectClickOutside';
// @ts-ignore
import styles from './ColorPicker.module.scss';
import {
  colorToSameTypeString,
  hasAlpha,
  stringToSameTypeColor,
} from '@utils/color/';
import { Button } from '../button';
import { Color } from '@clerk/types';

export type ColorPickerProps = {
  id?: string;
  name?: string;
  active?: boolean;
  className?: string;
  defaultColor?: string;
  hasError?: boolean;
  placement?: 'top' | 'bottom';
  handleChange?: (el: ColorPickerSelection) => void;
  handleError?: (error: string) => void;
  presetColors?: string[];
  disableAlpha?: boolean;
};

export type ColorPickerSelection = {
  name: string;
  value: string;
  type: 'color-picker';
};

export function ColorPicker({
  id,
  name,
  active,
  className,
  defaultColor = '#6c47ff',
  placement = 'bottom',
  handleChange,
  hasError,
  handleError,
  presetColors = [],
  disableAlpha = false,
}: ColorPickerProps): JSX.Element {
  const colorPickerRef = React.useRef<HTMLDivElement>(null);
  const [isActive, setIsActive] = useDetectClickOutside(
    colorPickerRef,
    !!active,
  );
  const [color, setColor] = React.useState<Color>(
    stringToSameTypeColor(defaultColor),
  );
  const stringFormattedColor = colorToSameTypeString(color);

  React.useEffect(() => {
    setColor(stringToSameTypeColor(defaultColor));
  }, [defaultColor]);

  const emitColor = (color: Color) => {
    if (!name || typeof handleChange !== 'function') {
      return;
    }
    handleChange({
      name,
      value: colorToSameTypeString(color),
      type: 'color-picker',
    });
  };

  const setAndEmitColor = (val: Color | string, emit = true) => {
    const newColor = typeof val === 'string' ? stringToSameTypeColor(val) : val;
    if (!newColor || (disableAlpha && hasAlpha(newColor))) {
      setColor(val);
      handleError?.('is invalid');
      return;
    }

    handleError?.('');
    setColor(newColor);
    if (emit) {
      emitColor(newColor);
    }
  };

  const handleColorChange = (colorResult: ColorResult, emit = true) => {
    const newColor: Color = hasAlpha(colorResult.rgb)
      ? colorResult.rgb
      : colorResult.hex;
    setAndEmitColor(newColor, emit);
  };

  function handlePaste(e: React.ClipboardEvent) {
    setAndEmitColor(e.clipboardData.getData('text/plain'), true);
  }

  function handleClick() {
    setIsActive(!isActive);
  }

  // TODO: Make color picker accessible and handle open and close onBlur and Focus
  // TODO: Leverage react popper for automatic picker placement or leverage <Dropdown/> after adding autoplacement to it
  return (
    <div
      ref={colorPickerRef}
      className={cn(styles.container, className)}
      aria-label={stringFormattedColor}
      onPaste={handlePaste}
    >
      <InputWithIcon
        Icon={
          <DropletButton onClick={handleClick} fill={stringFormattedColor} />
        }
        id={id}
        name={name}
        type='text'
        value={hasError ? (color as string) : stringFormattedColor}
        placeholder='e.g #6c47ff'
        readOnly
        onClick={handleClick}
        hasError={hasError}
      />
      {isActive && (
        <SketchPicker
          color={color}
          disableAlpha={disableAlpha}
          className={cn(styles.colorPicker, {
            [styles.bottom]: placement === 'bottom',
            [styles.top]: placement === 'top',
          })}
          onChange={color => handleColorChange(color, false)}
          onChangeComplete={color => handleColorChange(color, true)}
          presetColors={presetColors}
        />
      )}
    </div>
  );
}

function DropletButton({
  onClick,
  fill,
}: {
  onClick: () => void;
  fill: string;
}) {
  return (
    <Button
      type='button'
      flavor='icon'
      onClick={onClick}
      className={styles.iconButton}
    >
      <DropletIcon fill={fill} />
    </Button>
  );
}
