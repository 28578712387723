import { useDashboardCRUD, useDashboardSWR } from '@hooks';
import useSamlConnectionsPathAndKeys from './hooks/useSamlConnectionsPathAndKeys';

import { SamlConnection, SamlConnectionListResponse } from '@types';
import { useSWRConfig } from 'swr';

type UseGetSamlConnectionsReturn = {
  samlConnections: SamlConnection[];
  hasLoaded: boolean;
  mutate: () => void;
  totalCount: number;
};

export const useGetSamlConnections = ({
  orderBy,
}: {
  orderBy?: string;
}): UseGetSamlConnectionsReturn => {
  const { pathWithParams } = useSamlConnectionsPathAndKeys({
    orderBy,
  });

  const {
    data,
    mutate: SWRMutate,
    error,
  } = useDashboardSWR<SamlConnectionListResponse>(() => {
    if (!pathWithParams) {
      return null;
    }

    return pathWithParams;
  }, {});

  const hasLoaded = !!data && !error;

  const mutate = () => {
    void SWRMutate();
  };

  return {
    samlConnections: data?.data || [],
    hasLoaded,
    mutate,
    totalCount: data?.total_count || 0,
  };
};

type UseGetSamlConnectionReturn = {
  data: SamlConnection;
  hasLoaded: boolean;
  mutate: () => void;
};

export const useGetSamlConnection = (
  id: string,
): UseGetSamlConnectionReturn => {
  const { path } = useSamlConnectionsPathAndKeys();

  const {
    data,
    error,
    mutate: SWRMutate,
  } = useDashboardSWR<SamlConnection>(`${path}/${id}`);

  const hasLoaded = !!data && !error;

  const mutate = () => {
    void SWRMutate();
  };

  return {
    data,
    hasLoaded,
    mutate,
  };
};

type CreateSamlConnectionProps = {
  name: string;
  domain: string;
  idp_sso_url: string;
  idp_entity_id: string;
  idp_certificate: string;
};

type UseCreateSamlConnectionReturn = {
  createSamlConnection: (
    data: CreateSamlConnectionProps,
  ) => Promise<SamlConnection>;
};

export const useCreateSamlConnection = (): UseCreateSamlConnectionReturn => {
  const { path } = useSamlConnectionsPathAndKeys();
  const { create } = useDashboardCRUD<SamlConnection>();

  const createSamlConnection = async (data: CreateSamlConnectionProps) => {
    return await create(path, { ...data });
  };

  return { createSamlConnection };
};

type UpdateSamlConnectionProps =
  | CreateSamlConnectionProps
  | { active: boolean };

type UseUpdateSamlConnectionReturn = {
  updateSamlConnection: (
    id: string,
    data: UpdateSamlConnectionProps,
  ) => Promise<SamlConnection>;
  mutate: () => void;
};

export const useUpdateSamlConnection = (): UseUpdateSamlConnectionReturn => {
  const { cacheKey, path } = useSamlConnectionsPathAndKeys();
  const { mutate: mutateSWR } = useSWRConfig();
  const { update } = useDashboardCRUD<SamlConnection>();

  const updateSamlConnection = async (id, data: UpdateSamlConnectionProps) => {
    return await update(`${path}/${id}`, { ...data });
  };

  const mutate = () => {
    void mutateSWR(cacheKey);
  };

  return { updateSamlConnection, mutate };
};

type DeleteSamlConnectionReturn = {
  deleteSamlConnection: (id: string) => Promise<SamlConnection>;
  mutate: () => void;
};

export const useDeleteSamlConnection = (): DeleteSamlConnectionReturn => {
  const { cacheKey, path } = useSamlConnectionsPathAndKeys({});
  const { mutate: mutateSWR } = useSWRConfig();
  const { del } = useDashboardCRUD<SamlConnection>();

  const deleteSamlConnection = async (id: string) => {
    const samlConnection = await del(`${path}/${id}`);
    return samlConnection;
  };

  const mutate = () => {
    void mutateSWR(cacheKey);
  };

  return { deleteSamlConnection, mutate };
};
