import React from 'react';
import Link from 'next/link';
import {
  Flex,
  Image,
  Box,
  Switch,
  Text,
  Tooltip,
  VStack,
  Badge,
} from '@chakra-ui/react';
import { CardContainer, Subtitle } from '../common/Card/CardElements';

type ActiveIntegrationCardProps = {
  logo: string;
  name: string;
  websiteUrl: string;
  docsUrl: string;
  description: React.ReactNode;
  tags?: React.ReactNode;
  switchValue: boolean;
  tooltipLabel?: React.ReactNode;
  isDisabled?: boolean;
  isLoading: boolean;
  nonClickable?: boolean;
  opaque?: boolean;
  showPremiumBadge?: boolean;
  openModal?: () => void;
  onSwitchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export function ActiveIntegrationCard({
  logo,
  name,
  websiteUrl,
  docsUrl,
  description,
  tooltipLabel,
  tags,
  isDisabled,
  isLoading,
  switchValue,
  openModal,
  onSwitchChange,
  showPremiumBadge,
  ...rest
}: ActiveIntegrationCardProps): JSX.Element {
  const switchButton = (
    <Switch
      size='lg'
      isChecked={switchValue}
      onChange={onSwitchChange}
      isDisabled={isDisabled || isLoading}
      data-testid={`integration-card-switch-${name}`}
    />
  );

  const switchButtonWithTooltip = tooltipLabel ? (
    <Tooltip label={tooltipLabel}>
      <Box>{switchButton}</Box>
    </Tooltip>
  ) : (
    switchButton
  );

  return (
    <CardContainer {...rest}>
      <VStack align='flex-start' spacing='4'>
        <Image src={logo} alt={name} />

        <Box w='full'>
          <Flex align='center' justify='space-between' mb={1} w='full'>
            <Flex>
              <Text textStyle='md-medium'>{name}</Text>
              {showPremiumBadge && <Badge ml='2'>Premium</Badge>}
            </Flex>
            {switchButtonWithTooltip}
          </Flex>
          {websiteUrl && <Subtitle mb='1'>{websiteUrl}</Subtitle>}
          {tags}
        </Box>

        <Text textStyle='sm-normal' color='gray.500'>
          {description}
        </Text>

        <Flex>
          {switchValue && openModal && !isLoading && (
            <Text
              as='button'
              textStyle='sm-medium'
              color='primary.500'
              onClick={openModal}
              mr='3'
            >
              Manage integration
            </Text>
          )}

          <Link href={docsUrl} target='_blank'>
            <Box textStyle='sm-medium' color='primary.500'>
              Documentation
            </Box>
          </Link>
        </Flex>
      </VStack>
    </CardContainer>
  );
}
