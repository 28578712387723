import React from 'react';
import { BaseSchema, SamlConnection } from '@types';
import { formatRel } from '@components/users/utils';
import NameCell from './NameCell';
import StatusCell from './StatusCell';

export type SamlConnectionSchema = BaseSchema<SamlConnection>;

export const samlConnectionSchema = (): SamlConnectionSchema => [
  {
    header: 'Name',
    cell: samlConnection => <NameCell samlConnection={samlConnection} />,
    key: 'name',
    tdProps: {
      color: 'gray.900',
      borderTopLeftRadius: 'md',
      borderBottomLeftRadius: 'md',
    },
  },
  {
    header: 'Status',
    cell: samlConnection => <StatusCell samlConnection={samlConnection} />,
    key: 'status',
  },
  {
    header: 'Users',
    cell: samlConnection => samlConnection.user_count || '0',
    key: 'user_count',
  },
  {
    header: 'Created',
    cell: data => formatRel(data.created_at),
    key: 'created_at',
  },
];
