import React from 'react';
import { Badge } from '@chakra-ui/react';
import { SamlConnection } from '@types';

type StatusCellProps = {
  samlConnection: Pick<SamlConnection, 'active'>;
};
const StatusCell = ({ samlConnection }: StatusCellProps): JSX.Element => {
  return samlConnection.active ? (
    <Badge colorScheme='success'>Active</Badge>
  ) : (
    <Badge colorScheme='danger'>Inactive</Badge>
  );
};

export default StatusCell;
