import { SAML_IDPS, SamlIdpSlug } from '@types';

// TODO Port these to @clerk/types

export function getSamlProviderLogoURL(
  provider: SamlIdpSlug = 'saml_custom',
): string {
  return SAML_IDPS[provider]?.logoUrl;
}

export function getSamlProviderName(
  provider: SamlIdpSlug = 'saml_custom',
): string {
  return SAML_IDPS[provider]?.name;
}
