import React from 'react';
import { Text } from '@chakra-ui/react';
import { Modal } from '@components/common';
import { FileDropArea } from '@components/common/ImageUploader/FileDropArea';

export type CertificateUploaderProps = {
  handleSuccess: (content: string) => void;
  isModalOpen: boolean;
  closeModal: () => void;
};

export function CertificateUploader({
  handleSuccess,
  isModalOpen,
  closeModal,
}: CertificateUploaderProps): JSX.Element {
  const [hasFileError, setHasFileError] = React.useState(false);

  const onSuccess = (f: File) => {
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      void handleSuccess(fileReader.result as string);
    };
    fileReader.readAsText(f);
  };

  const onError = () => {
    setHasFileError(true);
  };

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        size='lg'
        title='Upload a certificate'
        subtitle='Add a certificate'
      >
        <Modal.Body>
          <FileDropArea
            acceptedTypes={[]}
            sizeLimitBytes={10 * 1000}
            handleSuccess={onSuccess}
            handleError={onError}
          />

          {hasFileError && (
            <Text textStyle='sm-normal' mt='4' color='gray.500'>
              Error uploading your certificate
            </Text>
          )}
        </Modal.Body>
        <Modal.ActionFooter
          leftButtonMessage='Close'
          onLeftButtonClicked={closeModal}
        />
      </Modal>
    </>
  );
}
