import React from 'react';
import NextLink from 'next/link';
import { SamlConnectionTable } from './SamlConnectionTable';
import { VStack, Box, HStack, Button } from '@chakra-ui/react';
import Filters from './Filters';
import { PlusIcon } from '@heroicons/react/solid';
import { Link as ChakraLink } from '@chakra-ui/react';
import { useLocation } from '@hooks';

export function SamlConnectionList(): JSX.Element {
  const { applicationId, instanceId } = useLocation();

  return (
    <VStack
      alignItems='self-start'
      spacing={2}
      width='100%'
      flexDirection='column'
    >
      <HStack justifyContent='space-between' width='100%'>
        <Filters />

        <NextLink
          href={`/apps/${applicationId}/instances/${instanceId}/user-authentication/enterprise-connections/new`}
        >
          <Button
            as={ChakraLink}
            aria-label='create connection'
            leftIcon={<PlusIcon width={16} height={16} color='white' />}
            _hover={{
              color: 'white',
            }}
          >
            Create connection
          </Button>
        </NextLink>
      </HStack>

      <Box width='100%'>
        <SamlConnectionTable />
      </Box>
    </VStack>
  );
}
