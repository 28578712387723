import React from 'react';
import { Stack } from '@chakra-ui/layout';
import { SamlConnection } from '@types';
import { useInstance, useLocation, useToast } from '@hooks';
import {
  CardDualPanel,
  CardWithSwitch,
  CopyTextInput,
  ReadonlyInputBox,
} from '@components/common';
import { Box, Center, Spinner, Switch, Text } from '@chakra-ui/react';
import { useForm, FormProvider } from 'react-hook-form';
import { CompleteConnectionNotice } from '@components/samlConnections/components/CompleteConnectionNotice';
import {
  useGetSamlConnection,
  useUpdateSamlConnection,
} from '@components/samlConnections/api';

export function SamlConnectionSettings(): JSX.Element {
  const { query } = useLocation();
  const { id: samlConnectionId } = query as Record<string, string | undefined>;
  const { updateSamlConnection } = useUpdateSamlConnection();
  const { showSuccessToast, showErrorToast } = useToast();

  const formMethods = useForm<SamlConnection>({ mode: 'onChange' });
  const { register, watch, reset } = formMethods;

  const isChecked = watch('active');

  const { instance, isValidating: isValidatingInstance } = useInstance();

  const {
    data: samlConnection,
    hasLoaded: hasLoadedSamlConnection,
    mutate: mutateSamlConnection,
  } = useGetSamlConnection(samlConnectionId);

  React.useEffect(() => {
    if (!samlConnection) {
      return;
    }

    const { active } = samlConnection;

    reset({ active });
  }, [samlConnection, reset]);

  const handleSwitchChange = async e => {
    const isChecked = e.target.checked;

    try {
      await updateSamlConnection(samlConnectionId, { active: isChecked });
      showSuccessToast('Connections settings saved.');
      void mutateSamlConnection();
    } catch (_) {
      showErrorToast('Something went wrong, please try again later.');
    }
  };

  if (
    (isValidatingInstance && !instance) ||
    (!hasLoadedSamlConnection && !samlConnection)
  ) {
    return (
      <Center w='full' h='full'>
        <Spinner />
      </Center>
    );
  }

  return (
    <Stack spacing='8'>
      <CompleteConnectionNotice />

      <FormProvider {...formMethods}>
        <CardWithSwitch
          title='Enable connection'
          subtitle='Allow users to authenticate using this enterprise connection.'
        >
          <Box w='48px' h='48px'>
            <Switch
              {...register('active')}
              isChecked={isChecked}
              onChange={handleSwitchChange}
            />
          </Box>
        </CardWithSwitch>
      </FormProvider>

      <CardDualPanel title='Connection details' subtitle=''>
        <ReadonlyInputBox label='Domain' paddingTop={0}>
          <Text textStyle='md-normal' color='gray.500'>
            {samlConnection.domain}
          </Text>
        </ReadonlyInputBox>
      </CardDualPanel>

      <CardDualPanel
        title='Identity provider configuration'
        subtitle='Retrieve these values from your identity provider and input them here.'
      >
        <ReadonlyInputBox label='IdP SSO URL' paddingTop={0}>
          <Text textStyle='md-normal' color='gray.500'>
            {samlConnection.idp_sso_url}
          </Text>
        </ReadonlyInputBox>

        <ReadonlyInputBox label='IdP Entity ID' paddingTop={0}>
          <Text textStyle='md-normal' color='gray.500'>
            {samlConnection.idp_entity_id}
          </Text>
        </ReadonlyInputBox>

        <ReadonlyInputBox label='Certificate' paddingTop={0}>
          <Text textStyle='md-normal' color='gray.500'>
            {samlConnection.idp_certificate
              ? 'Certificate uploaded'
              : 'No certificate uploaded'}
          </Text>
        </ReadonlyInputBox>
      </CardDualPanel>

      <CardDualPanel
        title='Service provider details'
        subtitle='Input these values into your IdP to complete the connection.'
      >
        <CopyTextInput
          label='Assertion consumer service URL'
          valueToCopy={samlConnection.acs_url}
          paddingTop={0}
        />

        <CopyTextInput
          label='Service provider entity ID'
          valueToCopy={samlConnection.sp_entity_id}
          paddingTop={0}
        />

        <CopyTextInput
          label='Sign-in URL'
          valueToCopy={instance.active_display_config.sign_in_url}
          paddingTop={0}
        />
      </CardDualPanel>
    </Stack>
  );
}
