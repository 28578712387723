import React from 'react';

import NextLink from 'next/link';

import { useLocation } from '@hooks';

import { Box, MenuItem } from '@chakra-ui/react';

type ViewProfileActionProps = { id: string };

const ViewProfileAction = ({ id }: ViewProfileActionProps): JSX.Element => {
  const { query } = useLocation();
  const { applicationId, instanceId } = query;

  return (
    <Box
      aria-label='View profile'
      as={NextLink}
      href={{
        pathname: `/apps/${applicationId}/instances/${instanceId}/users/${id}`,
        query: { userId: id },
      }}
      _hover={{
        bg: 'gray.50',
        color: 'currentColor',
      }}
    >
      <MenuItem>View profile</MenuItem>
    </Box>
  );
};

export default ViewProfileAction;
