import React, { useState } from 'react';
import { Box, Flex, Icon, Text, useDisclosure } from '@chakra-ui/react';
import { DocumentIcon, UploadIcon } from '@heroicons/react/solid';
import { CertificateUploader } from './CertificateUploader';
import { useController, useFormContext } from 'react-hook-form';

export const CertificateUploaderBox = (): JSX.Element => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isCertUploaded, setIsCertUploaded] = useState(false);
  const { control } = useFormContext();
  const {
    field: { onChange },
  } = useController({
    name: 'idp_certificate',
    control,
    rules: { required: 'Certificate is required' },
  });

  const handleSuccess = (content: string) => {
    onClose();
    setIsCertUploaded(true);
    onChange(content);
  };

  return (
    <>
      <CertificateUploader
        isModalOpen={isOpen}
        closeModal={onClose}
        handleSuccess={handleSuccess}
      />

      <Flex p={4} mb={2} justifyContent='space-between'>
        <Flex>
          <Box>
            <Icon
              as={isCertUploaded ? DocumentIcon : UploadIcon}
              color='gray.300'
              mr='1rem'
              position='relative'
              top='-3px'
              boxSize='20px'
            />
          </Box>
          <Flex direction='column'>
            <Text textStyle='md-normal' mb={1}>
              {isCertUploaded ? 'Certificate selected' : 'No file selected'}
            </Text>

            <Flex>
              <Text
                as='button'
                type='button'
                textStyle='sm-medium'
                color='primary.500'
                style={{ whiteSpace: 'nowrap' }}
                onClick={onOpen}
              >
                {isCertUploaded ? 'Replace' : 'Upload'}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};
