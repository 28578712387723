import React from 'react';
import Link from 'next/link';
import { Box, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { useApplication } from '@hooks';
import { comingSoonIntegrations } from './constants';
import { ComingSoonIntegrationCard } from './ComingSoonIntegrationCard';
import { CardSinglePanel, InformationBox, PageLoader } from '../common';
import { FirebaseCard } from './firebase';
import { GoogleAnalyticsCard } from './googleAnalytics';
import { HasuraCard } from './hasura';

export const Integrations = (): JSX.Element => {
  const { application } = useApplication();

  if (!application) {
    return <PageLoader />;
  }

  // The legacy Hasura integration is deprecated in favor on new JWT Templates
  const shouldShowHasuraCard =
    new Date(application.created_at).getTime() <
    new Date('2022-02-17').getTime();

  return (
    <Stack spacing={7}>
      <SimpleGrid minChildWidth='320px' spacing='32px' mx='auto' w='full'>
        <FirebaseCard />
        <GoogleAnalyticsCard />
        {shouldShowHasuraCard && <HasuraCard />}
      </SimpleGrid>

      <CardSinglePanel title='Coming soon'>
        <InformationBox icon={ExclamationCircleIcon}>
          <Text textStyle='md-normal' display='inline-block' color='gray.500'>
            Need an integration but don’t see it here?{' '}
            <Link href='mailto:support@clerk.com'>
              <Box as='span' textTransform='unset' color='primary.500'>
                Request integration
              </Box>
            </Link>
          </Text>
        </InformationBox>

        <SimpleGrid minChildWidth='245px' spacing='0' mx='auto' mt='4'>
          {comingSoonIntegrations.map(({ name, logo, websiteUrl }) => (
            <ComingSoonIntegrationCard
              key={name}
              name={name}
              logo={logo}
              websiteUrl={websiteUrl}
            />
          ))}
        </SimpleGrid>
      </CardSinglePanel>
    </Stack>
  );
};
