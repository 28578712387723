import React, { useState } from 'react';

import { START_BUILDING_FRAMEWORKS } from '@constants';
import { Framework } from '@types';
import { CardSinglePanel } from '@components/common';

import { QuickstartGuideTiles } from './QuickstartGuideTiles';
import { QuickstartGuideTabs } from './QuickstartGuideTabs';

export function StartBuilding(): JSX.Element {
  const [framework, setFramework] = useState<Framework>(
    START_BUILDING_FRAMEWORKS[0],
  );

  const onClick = (startItem: Framework) => {
    setFramework(
      START_BUILDING_FRAMEWORKS.find(item => item.slug === startItem.slug),
    );
  };

  return (
    <CardSinglePanel
      title='Quickstarts'
      subtitle='Choose your framework below. The average installation takes only 7 minutes.'
      subtitleStyles={{ mb: 6 }}
    >
      <QuickstartGuideTiles framework={framework} click={onClick} />
      {framework && <QuickstartGuideTabs framework={framework} />}
    </CardSinglePanel>
  );
}
