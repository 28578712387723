import React from 'react';

import {
  Button,
  Icon,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { DotsHorizontalIcon } from '@heroicons/react/solid';
import { SamlConnection } from '@types';
import NextLink from 'next/link';
import { useLocation } from '@hooks';

type RowActionsProps = {
  samlConnection: SamlConnection;
};

const RowActions = ({ samlConnection }: RowActionsProps): JSX.Element => {
  const { applicationId, instanceId } = useLocation();

  return (
    <Menu placement='left-start'>
      <MenuButton
        aria-label='table action button'
        as={Button}
        variant='link'
        _hover={{
          svg: {
            color: 'primary.500',
          },
        }}
      >
        <Icon as={DotsHorizontalIcon} boxSize={5} color='gray.300' />
      </MenuButton>
      <MenuList>
        <NextLink
          href={{
            pathname: `/apps/${applicationId}/instances/${instanceId}/user-authentication/enterprise-connections/${samlConnection.id}`,
          }}
          passHref
        >
          <MenuItem
            as={Link}
            _hover={{
              color: 'currentColor',
              bg: 'gray.50',
            }}
          >
            View connection
          </MenuItem>
        </NextLink>
        <NextLink
          href={{
            pathname: `/apps/${applicationId}/instances/${instanceId}/user-authentication/enterprise-connections/${samlConnection.id}/edit`,
          }}
          passHref
        >
          <MenuItem
            as={Link}
            _hover={{
              color: 'currentColor',
              bg: 'gray.50',
            }}
          >
            Edit connection
          </MenuItem>
        </NextLink>
      </MenuList>
    </Menu>
  );
};

export default React.memo(RowActions);
