import React from 'react';
import Link from 'next/link';
import { Button, Flex, HStack, Box, Text, Tooltip } from '@chakra-ui/react';
import { ClerkLogo } from '../common';

interface PlanCardProps {
  title: string;
  description: string | JSX.Element;
  ctaText?: string;
  ctaDisabled?: boolean;
  ctaHref?: string;
  ctaOnClick?: () => void;
  ctaComponent?: JSX.Element;
  isDisabledForOrganizationMembers?: boolean;
}

export function PlanCard({
  title,
  description,
  ctaText,
  ctaHref,
  ctaDisabled = false,
  ctaOnClick,
  ctaComponent,
  isDisabledForOrganizationMembers,
}: PlanCardProps): JSX.Element {
  return (
    <Flex p='4'>
      <Box>
        <ClerkLogo fill='gray.300' width='5' height='5' />
      </Box>
      <Box ml='4'>
        <Text textStyle='md-medium' mb='1'>
          {title}
        </Text>
        <Box textStyle='sm-normal' mb='2' color='gray.500'>
          {description}
        </Box>
        <HStack spacing='4'>
          {ctaHref && (
            <Link href={ctaHref}>
              <Box as='span' textStyle='sm-medium' color='primary.500'>
                {ctaText}
              </Box>
            </Link>
          )}

          <HStack alignItems='baseline'>
            {ctaText && ctaOnClick && (
              <Tooltip
                isDisabled={!isDisabledForOrganizationMembers}
                placement='top'
                label='Available to organization administrators.'
                aria-label='Available to organization administrators.'
              >
                <Button
                  variant='link'
                  textTransform='none'
                  color='primary.500'
                  fontWeight='bold'
                  onClick={ctaOnClick}
                  isDisabled={ctaDisabled}
                >
                  {ctaText}
                </Button>
              </Tooltip>
            )}

            {ctaComponent}

            <Link href='https://clerk.com/pricing'>
              <Box as='span' textStyle='sm-normal' color='primary.500'>
                View plan features
              </Box>
            </Link>
          </HStack>
        </HStack>
      </Box>
    </Flex>
  );
}
