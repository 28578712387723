import React from 'react';

import { DebouncedSearchInput } from '@components/common';
import { HStack, Box } from '@chakra-ui/react';

import { useRouter } from 'next/router';
import { useLocation } from '@hooks';

const Filters = (): JSX.Element => {
  const router = useRouter();
  const { query } = useLocation();

  const onSearchChange = (search: string) => {
    void router.push({
      query: {
        ...router.query,
        page: 1,
        query: search,
      },
    });
  };

  return (
    <HStack flex={1}>
      <Box width='100%' maxWidth={72}>
        <DebouncedSearchInput
          value={(query.query as string) || ''}
          onChange={onSearchChange}
        />
      </Box>
    </HStack>
  );
};

export default Filters;
