import React, { FunctionComponent } from 'react';
import { useLocation } from '@hooks';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
} from '@chakra-ui/react';
import { NavItem } from './NavItem';

interface NavExpandableProps {
  children: React.ReactNode;
  icon: FunctionComponent;
  label: string;
}

type AccordionChildProps = {
  readonly isAccordionChild: boolean;
};

export function NavExpandable({
  children,
  icon,
  label,
}: NavExpandableProps): JSX.Element {
  const {
    currentPage,
    query: { templateType },
  } = useLocation();

  const hasActiveChild = React.useMemo(
    () =>
      React.Children.toArray(children).some(child => {
        return (
          React.isValidElement(child) &&
          (child.props.href === currentPage ||
            currentPage
              ?.replace('[templateType]', `${templateType}`)
              .startsWith(child.props.href))
        );
      }),
    [children, currentPage, templateType],
  );

  const shouldBeExpanded = hasActiveChild ? 0 : undefined;

  return (
    <Accordion allowToggle variant='sidenav' defaultIndex={shouldBeExpanded}>
      <AccordionItem
        sx={{
          '.chakra-collapse': {
            overflow: 'initial !important',
          },
        }}
      >
        {({ isExpanded }) => (
          <>
            <AccordionButton
              /* remove click focus */
              _focus={{}}
            >
              <NavItem
                icon={icon}
                isAccordionParent
                isActive={hasActiveChild}
                isExpanded={isExpanded}
                label={label}
              />
            </AccordionButton>

            <AccordionPanel>
              {React.Children.map(children, child => {
                return React.isValidElement(child)
                  ? React.cloneElement(
                      child as React.ReactElement<AccordionChildProps>,
                      { isAccordionChild: true },
                    )
                  : child;
              })}
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
}
