import React from 'react';
import { useLocation } from '@hooks';
import DeleteSamlConnection from './DeleteSamlConnection';
import { useGetSamlConnection } from '@components/samlConnections/api';

export function SamlConnectionDanger(): JSX.Element {
  const { applicationId, instanceId, query } = useLocation();
  const { id: samlConnectionId } = query as Record<string, string | undefined>;
  const samlConnectionListPath = `/apps/${applicationId}/instances/${instanceId}/user-authentication/enterprise-connections`;

  const {
    data: samlConnection,
    hasLoaded,
    mutate,
  } = useGetSamlConnection(samlConnectionId);

  if (!hasLoaded) {
    return;
  }

  return (
    <DeleteSamlConnection
      samlConnection={samlConnection}
      navigateTo={samlConnectionListPath}
      onDelete={mutate}
    />
  );
}
