import React from 'react';

import {
  Flex,
  Text,
  UnorderedList,
  ListItem,
  Box,
  Icon,
  InputGroup,
  InputLeftAddon,
  Input,
} from '@chakra-ui/react';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import { InputBox } from '@components/common';

import useChooseDomainValidation from './useChooseDomainValidation';

export const FORM_ID = 'choose_domain';

const ChooseDomain = ({
  onDomainSelected,
  onDomainChange,
  newDomain,
  setError,
  error,
  subdomain,
  setIsSubmitting,
}: {
  subdomain: string;
  error: { message: string };
  setError: ({ message }: { message: string }) => void;
  newDomain: string;
  onDomainSelected: () => void;
  onDomainChange: (domain: string) => void;
  setIsSubmitting: (isSubmitting: boolean) => void;
}): JSX.Element => {
  const { validate } = useChooseDomainValidation(newDomain);

  const validateSubdomain = async () => {
    const { isValid, error } = await validate(setIsSubmitting);
    setError(error ? { message: error } : null);
    return isValid;
  };

  const onSubmit = async e => {
    e.preventDefault();
    const isFormValid = await validateSubdomain();

    if (isFormValid) {
      onDomainSelected();
    }
  };

  return (
    <form aria-label='choose-domain-form' id={FORM_ID} onSubmit={onSubmit}>
      <Flex gap={2} flexDirection='column'>
        <Text textStyle='md-normal'>
          Specify the root domain where your application lives, this will be
          used to determine the base for your DNS Records and the Clerk Frontend
          API for this instance.
        </Text>
        <Box width='100%'>
          <Flex
            borderRadius='md'
            p={4}
            mt={3.5}
            mb={1}
            bg='rgba(0, 0, 0, 0.04)'
          >
            <Flex mt={0.5} mr={2}>
              <Icon as={ExclamationCircleIcon} color='red.500' />
            </Flex>
            <Text textStyle='sm-normal' color='rgba(0,0,0,0.65)'>
              Changing the root domain will invalidate all active sessions, all
              your{' '}
              <Text as='span' textStyle='sm-normal' fontWeight='bold'>
                users will be signed out and downtime will occur.
              </Text>
            </Text>
          </Flex>
        </Box>

        <Box width='100%'>
          <Flex borderRadius='md' p={4} bg='rgba(0, 0, 0, 0.04)'>
            <Flex mt={0.5} mr={2}>
              <Icon as={ExclamationCircleIcon} color='red.500' />
            </Flex>
            <Flex flexDir='column'>
              <Text
                textStyle='sm-normal'
                color='rgba(0,0,0,0.65)'
                fontWeight='bold'
              >
                After changing root domains, you will need to perform the
                following actions:
              </Text>
              <UnorderedList mb={0}>
                <ListItem>
                  <Text textStyle='sm-normal' color='rgba(0,0,0,0.65)'>
                    Update the publishable key
                  </Text>
                </ListItem>
                <ListItem>
                  <Text textStyle='sm-normal' color='rgba(0,0,0,0.65)'>
                    Update DNS records
                  </Text>
                </ListItem>
                <ListItem>
                  <Text textStyle='sm-normal' color='rgba(0,0,0,0.65)'>
                    Generate new SSL certificates
                  </Text>
                </ListItem>
                <ListItem>
                  <Text textStyle='sm-normal' color='rgba(0,0,0,0.65)'>
                    If using social connections, update redirect URLs for
                    enabled social connections
                  </Text>
                </ListItem>
                <ListItem>
                  <Text textStyle='sm-normal' color='rgba(0,0,0,0.65)'>
                    If using JWT templates, update JWT issuer and JWKS endpoint
                    in external JWT SSO services
                  </Text>
                </ListItem>
              </UnorderedList>
            </Flex>
          </Flex>
        </Box>

        <Flex width='100%' direction='column' gap={4}>
          <Flex direction='column' gap={1}>
            <InputBox
              errorMessageProps={{ position: 'absolute' }}
              error={error}
              m={0}
              p={0}
              label='New  root domain'
            >
              <InputGroup>
                <InputLeftAddon color='rgba(0, 0, 0, 0.65)'>
                  https://{subdomain ? `${subdomain}.` : ''}
                </InputLeftAddon>

                <Input
                  placeholder='example.com'
                  value={newDomain}
                  onChange={e => onDomainChange(e.target.value.trim())}
                />
              </InputGroup>
            </InputBox>
          </Flex>
        </Flex>
      </Flex>
    </form>
  );
};

export default ChooseDomain;
