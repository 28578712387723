import React from 'react';
import { useUser } from '@clerk/nextjs';
import { CardInfoPanel, HelperLink } from '@components/common';
import { Box, Text } from '@chakra-ui/react';
import { useLocation } from '@hooks';

// TODO update with final URL
const ENTERPRISE_CONNECTIONS_GUIDE_URL = 'https://clerk.com/docs';

export function CompleteConnectionNotice(): JSX.Element | null {
  const { user } = useUser();
  const { query } = useLocation();
  const { id: samlConnectionId } = query as Record<string, string | undefined>;
  const key = `${samlConnectionId}_complete`;

  if (user.unsafeMetadata?.[key]) {
    return null;
  }

  const onDismiss = async () => {
    return await user.update({
      unsafeMetadata: {
        ...user.unsafeMetadata,
        [key]: true,
      },
    });
  };

  return (
    <CardInfoPanel
      title='You’re not quite done!'
      titleContainerStyles={{ mb: 2 }}
      onDismiss={onDismiss}
    >
      <Box>
        <Text textStyle='sm-normal' mb={2}>
          This connection must also be configured in your IdP.
        </Text>

        <Text textStyle='sm-normal' mb={2}>
          You must copy and paste values from Service provider details at the
          bottom of this page into your identity provider to complete the
          connection.
        </Text>

        <Text textStyle='sm-normal' mb={6}>
          This message can be closed once you complete the connection.
        </Text>

        <HelperLink display='block' href={ENTERPRISE_CONNECTIONS_GUIDE_URL}>
          Read enterprise connections guide
        </HelperLink>
      </Box>
    </CardInfoPanel>
  );
}
