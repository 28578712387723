import React from 'react';
import { useDisclosure } from '@chakra-ui/react';

import ConfirmChange, {
  FORM_ID as ConfirmationChangeDomain,
} from './ConfirmChange';
import ChooseDomain, { FORM_ID as ChooseDomainFormId } from './ChooseDomain';

type State = {
  isSubmitting: boolean;
  error: null | { message: string };
  currentStep: 'chooseDomain' | 'confirmation';
  newDomain: string;
};

const reducer = (currentState: State, update: Partial<State>) => ({
  ...currentState,
  ...update,
});

const useChangeDomain = ({
  previousSubdomain,
  instanceId,
  onDomainChangeConfirmed,
}: {
  previousSubdomain: string;
  instanceId: string;
  onDomainChangeConfirmed: () => void;
}): any => {
  const initialState: State = {
    isSubmitting: false,
    error: null,
    currentStep: 'chooseDomain',
    newDomain: '',
  };
  const { isOpen, getButtonProps, onClose } = useDisclosure();

  const [state, setState] = React.useReducer<
    React.Reducer<State, Partial<State>>
  >(reducer, { ...initialState });

  const handleClose = () => {
    setState({ ...initialState });
    onClose();
  };

  const onDomainSelected = () => {
    setState({
      currentStep: 'confirmation',
    });
  };

  const onDomainChange = (domain: string) => {
    const domainWithoutHttps = domain.replace(/^(https?:\/\/)?/, '');
    setState({ newDomain: domainWithoutHttps });
  };

  const setError = (error: { message: string }) => {
    setState({ error });
  };

  const setIsSubmitting = (isSubmitting: boolean) => {
    setState({ isSubmitting });
  };

  const handleOnDomainChangeConfirmed = () => {
    onDomainChangeConfirmed();
    handleClose();
  };

  const goBack = () => {
    setState({
      currentStep: 'chooseDomain',
    });
  };

  const makeStepsProps = {
    chooseDomain: {
      formId: ChooseDomainFormId,
      message: (
        <ChooseDomain
          subdomain={previousSubdomain}
          newDomain={state.newDomain}
          onDomainChange={onDomainChange}
          onDomainSelected={onDomainSelected}
          setError={setError}
          error={state.error}
          setIsSubmitting={setIsSubmitting}
        />
      ),
      verification: null,
      inputLeftAddon: '',
      title: 'Change root domain',
    },
    confirmation: {
      formId: ConfirmationChangeDomain,
      message: (
        <ConfirmChange
          subdomain={previousSubdomain}
          newDomain={state.newDomain}
          instanceId={instanceId}
          setIsSubmitting={setIsSubmitting}
          setError={setError}
          onDomainChangeConfirmed={handleOnDomainChangeConfirmed}
        />
      ),
      verification: {
        stringToMatch: state.newDomain,
        label: 'New root domain',
        text: 'Type the root new domain to confirm the change.',
        error: state.error,
      },
      inputLeftAddon: `https://${
        previousSubdomain ? `${previousSubdomain}.` : ''
      }`,
      title: 'Confirm new root domain name',
    },
  };

  return {
    isGoBackVisible: state.currentStep === 'confirmation',
    goBack,
    handleClose,
    getButtonProps,
    isOpen,
    isSubmitting: state.isSubmitting,
    isConfirmDisabled: state.newDomain === '',
    ...makeStepsProps[state.currentStep],
  };
};

export default useChangeDomain;
