import React from 'react';
import NextLink from 'next/link';

import { Stack, Flex, Text, Icon, Box } from '@chakra-ui/react';
import { ExclamationCircleIcon } from '@heroicons/react/solid';

const ChangePlan = ({
  message,
  changePlanLink,
}: {
  message: string;
  changePlanLink: string;
}): JSX.Element => {
  if (!message) {
    return null;
  }
  return (
    <Flex mb={4} borderRadius='md' p={4} bg='rgba(0, 0, 0, 0.04)'>
      <Flex mt={0.5} mr={2}>
        <Icon as={ExclamationCircleIcon} color='red.500' />
      </Flex>
      <Stack spacing={1} flexDirection='column'>
        <Flex alignItems='center'>
          <Text textStyle='md-normal' fontWeight='bold'>
            Cannot add more users.
          </Text>
        </Flex>
        <Text color='rgba(0, 0, 0, 0.65)' textStyle='sm-normal'>
          {message}
        </Text>
        <NextLink aria-label='navigate to billing' href={changePlanLink}>
          <Box
            as='span'
            mt={1}
            textStyle='sm-normal'
            color='primary.500'
            fontWeight={500}
          >
            Upgrade plan
          </Box>
        </NextLink>
      </Stack>
    </Flex>
  );
};

export default ChangePlan;
