import React from 'react';
import { Button, ButtonProps } from '@clerk-ui/components';
import { CheckmarkIcon, XIcon, LoaderIcon } from '@clerk-ui/assets/icons';

import styles from './StatusList.module.scss';
import { useDashboardCRUD } from '@hooks/useDashboardAPI';
import { Statuses } from '@types';
import { useRouter } from 'next/router';

type StatusListProps = {
  sslStatus: Statuses;
  emailStatus: Statuses;
};

export function StatusList({
  sslStatus,
  emailStatus,
}: StatusListProps): JSX.Element {
  const router = useRouter();
  const { id: instanceId } = router.query;
  const { create } = useDashboardCRUD();
  const [isSSLLoading, setSSLLoading] = React.useState(false);
  const [isEmailLoading, setEmailLoading] = React.useState(false);

  const retrySSL = async () => {
    setSSLLoading(true);
    try {
      await create(`/v1/instances/${instanceId}/status/ssl/retry`);
    } catch (_) {
      console.error(_);
    } finally {
      setSSLLoading(false);
    }
  };

  const retryEmail = async () => {
    setEmailLoading(true);
    try {
      await create(`/v1/instances/${instanceId}/status/mail/retry`);
    } catch (_) {
      console.error(_);
    } finally {
      setEmailLoading(false);
    }
  };

  return (
    <div>
      <ul className={styles.activation}>
        <li>
          <StatusIcon status={sslStatus} />
          <span>Issued TLS certificate</span>
          <RetryServiceButton
            disabled={isSSLLoading}
            status={sslStatus}
            retryAction={async () => await retrySSL()}
          />
        </li>
        <li>
          <StatusIcon status={emailStatus} />
          <span>Email server initialization</span>
          <RetryServiceButton
            disabled={isEmailLoading}
            status={emailStatus}
            retryAction={async () => await retryEmail()}
          />
        </li>
      </ul>
    </div>
  );
}

type NonProductionStatusListProps = {
  deployStatus: Statuses;
};

export function NonProductionStatusList({
  deployStatus,
}: NonProductionStatusListProps): JSX.Element {
  return (
    <div>
      <ul className={styles.activation}>
        <li>
          <StatusIcon status={deployStatus} />
          <span>Issued TLS certificate</span>
        </li>
      </ul>
    </div>
  );
}

type StatusIconProps = {
  status: Statuses;
};

function StatusIcon({ status }: StatusIconProps): JSX.Element {
  return status === Statuses.Complete ? (
    <CheckmarkIcon
      viewBox='0 0 16 16'
      stroke='#36ca36'
      width='1.25em'
      height='1.25em'
    />
  ) : status === Statuses.InProgress ? (
    <LoaderIcon className={styles.inProgressIcon} stroke='#6c47ff' />
  ) : (
    <XIcon stroke='#ff1d17' />
  );
}

type RetryServiceButtonProps = {
  retryAction: () => void;
  status: Statuses;
} & Omit<ButtonProps, 'children'>;

function RetryServiceButton({
  retryAction,
  status,
  ...rest
}: RetryServiceButtonProps): JSX.Element {
  return (
    status === Statuses.Failed && (
      <Button
        onClick={retryAction}
        className={styles.actionButton}
        flavor='text'
        {...rest}
      >
        Retry
      </Button>
    )
  );
}
