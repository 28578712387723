// @ts-nocheck
/**
 * TypeScript configuration (typings) is not correctly configured for all projects.
 * Consequently, the files are correctly imported but the TS checker emits errors.
 * The above no-check is safe, as webpack will not allow compilation if for example a file is not resolved.
 */
export { default as AlertOctagonIcon } from './alert-octagon.svg';
export { default as AnalyticsIcon } from './analytics.svg';
export { default as ArrowLeftIcon } from './arrow-left.svg';
export { default as ArrowRightIcon } from './arrow-right.svg';
export { default as PlanUpgradeBackground } from './background-plan-upgrade.svg';
export { default as BinIcon } from './bin.svg';
export { default as BoltIcon } from './bolt.svg';
export { default as CameraIcon } from './camera.svg';
export { default as CaretIcon } from './caret.svg';
export { default as CheckCircleIcon } from './check-circle.svg';
export { default as CheckmarkIcon } from './checkmark.svg';
export { default as ClerkLogoIcon } from './clerk-logo.svg';
export { default as ClipboardIcon } from './clipboard.svg';
export { default as ClockIcon } from './clock.svg';
export { default as CogIcon } from './cog.svg';
export { default as CornerDownRightIcon } from './corner-down-right.svg';
export { default as DesktopIcon } from './desktop.svg';
export { default as DropletIcon } from './droplet.svg';
export { default as ExternalLinkIcon } from './external-link.svg';
export { default as EyeIcon } from './eye.svg';
export { default as EyeOffIcon } from './eye-off.svg';
export { default as FacebookIcon } from './facebook.svg';
export { default as GoogleIcon } from './google.svg';
export { default as HelpCircleIcon } from './help-circle.svg';
export { default as HomeIcon } from './home.svg';
export { default as ImageDefaultIcon } from './image-default.svg';
export { default as ImageDefaultSmIcon } from './image-default-sm.svg';
export { default as InfoIcon } from './info.svg';
export { default as IntegrationsIcon } from './integrations.svg';
export { default as JwtIcon } from './jwt.svg';
export { default as LoaderIcon } from './loader.svg';
export { default as LogoMarkIcon } from './logo-mark.svg';
export { default as LogoPlateIcon } from './logo-plate.svg';
export { default as MailIcon } from './mail.svg';
export { default as MobileIcon } from './mobile.svg';
export { default as MoreVerticalIcon } from './more-vertical.svg';
export { default as PencilIcon } from './pencil.svg';
export { default as PhoneIcon } from './phone.svg';
export { default as RadioCheckboxIcon } from './radio-checkbox.svg';
export { default as RefreshIcon } from './refresh.svg';
export { default as SearchIcon } from './search.svg';
export { default as ServerIcon } from './server.svg';
export { default as ShieldIcon } from './shield.svg';
export { default as SignOutIcon } from './sign-out.svg';
export { default as SignInCheckIcon } from './signin-check.svg';
export { default as StackIcon } from './stack.svg';
export { default as TypeIcon } from './type.svg';
export { default as UploadIcon } from './upload.svg';
export { default as UserIcon } from './user.svg';
export { default as UserPlusIcon } from './user-plus.svg';
export { default as WarningIcon } from './warning.svg';
export { default as XIcon } from './x-icon.svg';
export { default as BillingModalBackground } from './billing-modal-background.svg';
export { default as RoundWarning } from './round-warning.svg';
export { default as WebhooksIcon } from './webhook.svg';
export { default as Web3Icon } from './web3.svg';
export { default as CautionIcon } from './caution.svg';
export { default as Selector } from './selector.svg';
export { default as Applications } from './applications.svg';
export { default as WelcomeGesture } from './welcome_gesture.svg';
export { default as Confetti } from './confetti.svg';
export { default as DocumentSearch } from './document-search.svg';
export { default as ShuffleIcon } from './shuffle.svg';
export { default as PlayIcon } from './play.svg';
