import React, { FunctionComponent } from 'react';
import { useLocation } from '@hooks';
import { Link as LinkChakra } from '@chakra-ui/react';
import NextLink from 'next/link';
import { noop, applicationInstancePath } from '@utils';
import { NavItem } from './NavItem';

function removeDynamicPortion(url: string) {
  const segments = url.split('/');

  if (segments.length <= 3) {
    if (segments.includes('new')) {
      return url.replace(/\/\[[a-zA-Z]+\]/, '').replace('/new', '');
    }

    return url.replace(/\/\[[a-zA-Z]+\]/, '');
  }

  return segments.slice(0, 3).join('/');
}

interface NavLinkProps {
  href?: string;
  icon?: FunctionComponent;
  isAccordionChild?: boolean;
  isExternal?: boolean;
  label: React.ReactNode;
  onClick?: () => void | undefined;
}

export const NavLink = ({
  href,
  isAccordionChild,
  isExternal,
  icon,
  label,
  onClick = noop,
}: NavLinkProps): JSX.Element => {
  const {
    applicationId,
    instanceId,
    currentPage,
    query: { templateType },
  } = useLocation();
  const isDisabled = !href;

  const currentUrl = removeDynamicPortion(
    currentPage?.replace('[templateType]', `${templateType}`),
  );

  const isActive = currentUrl === href;

  const item = (
    <LinkChakra
      w='236px'
      variant='no-focus'
      onClick={isDisabled ? undefined : onClick}
      target={href?.startsWith('https://') ? '_blank' : ''}
    >
      <NavItem
        isDisabled={isDisabled}
        icon={icon}
        isAccordionChild={isAccordionChild}
        isExternal={isExternal}
        isActive={(href !== '/' && isActive) || href === currentPage}
        label={label}
      />
    </LinkChakra>
  );

  if (isDisabled) {
    return item;
  }

  if (href.startsWith('https://')) {
    return (
      <NextLink passHref href={href}>
        {item}
      </NextLink>
    );
  }

  return (
    <NextLink
      passHref
      href={`${applicationInstancePath({
        applicationId,
        instanceId,
      })}${href}`}
    >
      {item}
    </NextLink>
  );
};
