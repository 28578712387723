import { svgUrl } from '@utils';

export type SamlConnection = {
  id: string;
  name: string;
  provider: SamlIdpSlug;
  domain: string;
  idp_entity_id: string;
  sp_entity_id: string;
  idp_sso_url: string;
  acs_url: string;
  idp_certificate: string;
  allow_idp_initiated: boolean;
  idp_emails_verified: boolean;
  active: boolean;
  user_count: number;
  created_at: Date;
  updated_at: Date;
};

export type SamlConnectionListResponse = {
  data: SamlConnection[];
  total_count: number;
};

// TODO Port these to @clerk/types

export type SamlIdpSlug =
  | 'saml_okta'
  | 'saml_google'
  | 'saml_microsoft'
  | 'saml_jumpcloud'
  | 'saml_rippling'
  | 'saml_onelogin'
  | 'saml_custom';

export type SamlIdp = {
  name: string;
  logoUrl: string;
};

export type SamlIdpMap = Record<SamlIdpSlug, SamlIdp>;

export const SAML_IDPS: SamlIdpMap = {
  saml_okta: {
    name: 'Okta Workforce',
    logoUrl: svgUrl('okta'),
  },
  saml_google: {
    name: 'Google Workspace',
    logoUrl: svgUrl('google'),
  },
  saml_microsoft: {
    name: 'Microsoft Azure AD',
    logoUrl: svgUrl('azure'),
  },
  saml_jumpcloud: {
    name: 'JumpCloud',
    logoUrl: svgUrl('jumpcloud'),
  },
  saml_rippling: {
    name: 'Rippling',
    logoUrl: svgUrl('rippling'),
  },
  saml_onelogin: {
    name: 'OneLogin',
    logoUrl: svgUrl('onelogin'),
  },
  saml_custom: {
    name: 'SAML',
    logoUrl: svgUrl('saml'),
  },
};
